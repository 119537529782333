import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {Store} from '../../models/store';

import {DataService} from '../../services/data.service';

import { normalize } from 'normalize-diacritics';

@Component({
  selector: 'app-bio',
  template: `
    <app-loader message="Netmenu Bio..." *ngIf="isLoading; else loaded"></app-loader>
    <ng-template #loaded>
      <!-- <app-header [logoUrl]="store?.logoUrl"
                  [photoUrl]="store?.photoUrl"
                  [storeName]="store?.name">
      </app-header> -->
      <div class="bio-content" id="netmenu-bio">
        <router-outlet></router-outlet>
      </div>
    </ng-template>
  `,
  styles: [`
    #netmenu-bio {
      all: revert!important;
    }
    :host-context(body) {
      background-color: #1C0732!important;
    }
  `],
})
export class BioComponent implements OnInit, OnDestroy {
  isLoading = true;
  store: Store;
  storeAlias: string;

  private subscription: Subscription = Subscription.EMPTY;

  constructor(private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private meta: Meta,
              private location: Location,
              private title: Title) {

    // Initialize store object to prevent errors in child object
    this.store = <Store>{ name: '', address: '' };

    // Check for invalid routes, diacritics and invalid chars
    this.subscription = this.activatedRoute.params.subscribe(parameters => {
      normalize(parameters.storeAlias).then(filterDiacritics => {
        const filterRegExp = new RegExp('[^a-zA-Z0-9]');
        var normalizedAlias = filterDiacritics.replace(filterRegExp, '');
        this.storeAlias = normalizedAlias;
        this.location.replaceState(`/bio/${normalizedAlias}`);
        this.router.navigate([`/bio/${normalizedAlias}`]);
        if (!this.storeAlias || this.storeAlias != 'mdcsoftware') {
          this.router.navigate(['not-found']);
          return;
        }
        // Valid route was found, proceed...
        this.getStore(this.storeAlias)
          .then(() => this.setStoreMetaTags());
        });
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async getStore(storeAlias): Promise<void> {
    const resp = await this.dataService.getStore(storeAlias);
    if (!resp) {
      this.router.navigate(['not-found']);
      return;
    }

    // Fix Cloudinary optimization hints (v0.0.35)
    resp.photoUrl = resp.photoUrl.replace('/upload/', '/upload/f_auto,q_auto/');
    resp.logoUrl = resp.logoUrl.replace('/upload/', '/upload/c_scale,w_100,h_100,f_auto,fl_lossy/');
    //

    this.store = resp;
    this.isLoading = false;
  }

  setStoreMetaTags(): void {
    // Page Title
    this.title.setTitle(this.store.name);
    // Robots
    this.meta.addTag({name: 'robots', content: 'follow, index'});
    // Facebook Meta Tags
    this.store.locale = 'pt_BR';
    this.meta.addTag({name: 'og:locale', content: this.store.locale});
    this.meta.addTag({name: 'og:type', content: 'website'});
    this.meta.addTag({name: 'og:title', content: this.store.name});
    this.meta.addTag({
      name: 'og:description',
      content: (this.store.subtitle ? this.store.subtitle : 'Netmenu powered by MDC')
    });
    this.meta.addTag({name: 'og:url', content: `//netmenu.mdc.com.br/bio/${this.storeAlias}`});
    this.meta.addTag({name: 'og:site_name', content: this.store.name});
    this.meta.addTag({name: 'og:image', content: `${this.store.logoUrl}?v=${Math.floor(Date.now() / 1000)}`});
    this.meta.addTag({
      name: 'og:image:secure_url',
      content: `${this.store.logoUrl}?v=${Math.floor(Date.now() / 1000)}`
    });
    // Twitter Meta Tags
    this.meta.addTag({name: 'twitter:card', content: 'summary_large_image'});
    this.meta.addTag({
      name: 'twitter:description',
      content: (this.store.subtitle ? this.store.subtitle : 'Netmenu powered by MDC')
    });
    this.meta.addTag({name: 'twitter:title', content: this.store.name});
    this.meta.addTag({name: 'twitter:image', content: `${this.store.photoUrl}?v=${Math.floor(Date.now() / 1000)}`});
  }
}
