import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

import { LocalStorageService, SharedStorageService } from 'ngx-store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { of, interval } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { AccountOrders } from '../models/accountOrder';
import { AppInfo } from '../models/appInfo';
import { addProduct, Cart, removeProduct } from '../models/cart';
import { Category } from '../models/category';
import { GroupCategory } from '../models/groupCategory';
import { Product } from '../models/product';
import { Store } from '../models/store';
import { mapToOrderItems, Order } from '../models/order';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DataService implements OnDestroy {
  readonly apiURL = environment.apiURL;
  cartObservable: BehaviorSubject<Cart>;

  constructor(private http: HttpClient,
              private toastr: ToastrService,
              private localStorageService: LocalStorageService,
              private sharedStorageService: SharedStorageService) {
    this.initCart();
  }

  private setAppInfo(storeAlias: string): void {
    const appInfo = this.getAppInfo();
    if (this.validateAppInfo(appInfo, storeAlias) || !this.validateStore()) {
      this.localStorageService.clear('all');
      this.localStorageService.set<AppInfo>('appInfo', this.renewAppInfo(storeAlias));
    }
  }

  validateAppInfo(appInfo: AppInfo, storeAlias: string): boolean {
    try {
      if (!appInfo) {
        return false;
      }
      const storedVersion = appInfo.version.replace(/\D/g, '');
      const envVersion = environment.appVersion.replace(/\D/g, '');
      if (appInfo.storeAlias !== storeAlias) {
        return false;
      }
      return storedVersion < envVersion;
    } catch (e) {
      return false;
    }
  }

  private renewAppInfo(storeAlias: string): AppInfo {
    const newAppInfo = new AppInfo();
    newAppInfo.storeAlias = storeAlias;
    newAppInfo.createdAt = new Date(moment.now());
    newAppInfo.version = environment.appVersion;
    return newAppInfo;
  }

  getAppInfo(): AppInfo {
    return this.localStorageService.get('appInfo');
  }

  private validateStore(): boolean {
    const appInfo = this.getAppInfo();
    if (!appInfo) {
      this.setOrderIdExternal(null);
      return false;
    }
    const duration = moment.duration(moment(moment.now()).diff(appInfo.createdAt));
    const hours = duration.asHours();
    if (hours >= 12) {
      this.setOrderIdExternal(null);
      return false;
    }
    return true;
  }

  storeAlias(): string {
    return this.sharedStorageService.get('storeAlias');
  }

  setStoreAlias(value: string): string {
    this.setAppInfo(value);
    return this.sharedStorageService.set('storeAlias', value);
  }

  store(): Store {
    return this.sharedStorageService.get('store');
  }

  setStore(value: Store): Store {
    return this.sharedStorageService.set('store', value);
  }

  cart(): Cart {
    return this.sharedStorageService.get('cart') || new Cart();
  }

  setCart(value: Cart): Cart {
    return this.sharedStorageService.set('cart', value);
  }

  orderIdExternal(): string {
    this.validateStore();
    return this.localStorageService.get('orderIdExternal');
  }

  setOrderIdExternal(value: string): string {
    return this.localStorageService.set('orderIdExternal', value);
  }

  private initCart(): void {
    if (!this.cartObservable) {
      this.cartObservable = new BehaviorSubject<Cart>(this.cart());
      this.cartObservable.subscribe(value => this.setCart(value));
    }
  }

  cleanCart(): void {
    this.cartObservable.next(new Cart());
  }

  async getStore(storeAlias: string): Promise<null | Store> {
    try {
      if (this.store() && this.store().alias === storeAlias) {
        return this.store();
      }
      this.setStoreAlias(storeAlias.trim());
      const apiURL = `${this.apiURL}menu/${storeAlias}`;
      const resp = await this.http.get<Store>(apiURL).toPromise();
      this.setStore(resp);
      this.validateCart(resp.alias);
      return resp;
    } catch (e) {
      console.log('Err', e);
    }
    return null;
  }

  async getGroupCategories(): Promise<GroupCategory[]> {
    return this.store().groupCategories || [];
  }

  getAllCategories(): Category[] {
    return this.store().groupCategories && this.store().groupCategories.length > 0 ?
      this.store().groupCategories
        .map(gc => gc.categories)
        .reduce(((previousValue, currentValue) => previousValue.concat(currentValue))) :
      this.store().categories;
  }

  async getProducts(categoryId: string): Promise<Product[]> {
    return this.getAllCategories().find((value) => value.id === categoryId).items;
  }

  removeProductInCart(product: Product): void {
    // get all products in cart
    const cart: Cart = Object.assign({}, this.cart());
    removeProduct(cart, product);
    this.cartObservable.next(cart);
  }

  addProductCountInCart(product: Product): void {
    // get all products in cart
    const cart: Cart = Object.assign({}, this.cart());
    addProduct(cart, product);
    this.cartObservable.next(cart);
  }

  async getOrder(storeAlias: string): Promise<{ store: Store, order: AccountOrders }> {
    try {
      const store = await this.getStore(storeAlias);
      const orderIdExternal = this.orderIdExternal();
      if (!orderIdExternal) {
        return null;
      }
      const apiURL = `${this.apiURL}orders/${store.storeId}/${orderIdExternal}`;
      const order = await this.http.get<AccountOrders>(apiURL).toPromise();
      return {
        store,
        order
      };
    } catch (e) {
      this.setOrderIdExternal(null);
      console.log('Err', e);
    }
    return null;
  }

  getCartObservable(): Observable<Cart> {
    return this.cartObservable;
  }

  async validateAndPostOrder(qrCodeToken: string): Promise<boolean> {
    this.setOrderIdExternal(qrCodeToken);
    return await this.sendOrder();
  }

  async sendOrder(): Promise<boolean> {
    const apiURL = `${this.apiURL}orders`;
    try {
      const payload = {
        storeId: this.store().storeId,
        orderIdExternal: this.orderIdExternal(),
        orderItems: mapToOrderItems(this.cart().cartProducts)
      } as Order;
      // @ts-ignore
      await this.http.post<string>(apiURL, payload, {responseType: 'text'}).toPromise();
      this.toastr.success('Pedido enviado com sucesso.');
      return true;
    } catch (e) {
      console.log('Err', e);
    }
    return false;
  }

  ngOnDestroy(): void {
  }

  private validateCart(alias: string): void {
    if (this.cart() && this.cart().storeAlias !== alias) {
      this.cleanCart();
    }
  }
}
