<div *ngIf="isLoading; else productsLoadedTemplate">
  <app-product-shimmer></app-product-shimmer>
</div>
<ng-template #productsLoadedTemplate>
  <div class="container border shadow rounded">
    <div class="row mb-lg-5 align-middle">
      <div title="Voltar" class="col-1 back-btn" (click)="onClickBack($event)">
        <i-bs name="arrow-left-circle" class="icon-back"></i-bs>
      </div>
      <div class="col-10 category-name-title">
        <h4 class="font-weight-bold text-center m-lg-4">
          {{category?.name}}
        </h4>
      </div>
    </div>
    <div class="list-group list-group-flush mb-5">
      <div class="list-group-item list-group-item-action product-content"
           [class.with-cart]="isOpen"
           *ngFor="let product of products">
        <div class="row product-cell ml-0" [class.mb-3]="isOpen" (click)="onProductClick(product)">
          <div class="product-info">
            <div class="d-none d-sm-block d-ml-block">    <!-- except on XS -->
              <div class="d-flex w-100">
                <h5 class="d-none d-sm-block mb-1 font-weight-bold">{{ product.name }}</h5>
              </div>
              <p class="mb-1 text-truncate product-description">{{ product.description }}</p>
              <p>{{ product.price | currency:'BRL':true }}</p>
            </div>
            <div class="d-block d-sm-none">               <!-- only on XS -->
              <div class="d-flex w-100">
                <h6 class="d-block d-sm-none product-title-h6">{{ product.name }}</h6>
              </div>
              <p class="small text-truncate m-0" style="width: 185px; max-height: 75px;word-break: break-word; white-space: break-spaces;">{{ product.description }}</p>
              <p class="small font-weight-bold"> {{ product.price | currency:'BRL':true}}</p>
            </div>
            <div class="relative">
              <div class="product-photo mr-lg-4">
                <img src="{{getPhotoUrl(product.photoUrl)}}" loading="lazy" class="rounded product-photo-image"
                    style="object-fit: contain;"
                    onerror="this.src = 'https://img.icons8.com/pastel-glyph/512/error.png';this.style.width = '100px';this.style.height = '100px'; this.title='Não foi possível carregar imagem.'"/>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="isOpen">
          <div class="col-lg-12 text-right">
            <app-button-add-to-cart
              (onClickAdd)="onClickAddToCart(product)">
            </app-button-add-to-cart>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
