import { RouterModule } from '@angular/router';
import {registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {QRCodeModule} from 'angularx-qrcode';
import {allIcons, NgxBootstrapIconsModule} from 'ngx-bootstrap-icons';
import {NgxShimmerLoadingModule} from 'ngx-shimmer-loading';
import {NgxSpinnerModule} from 'ngx-spinner';
import {WebStorageModule} from 'ngx-store';
import {ToastrModule} from 'ngx-toastr';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {ComponentsModule} from './components/components.module';
import {GlobalErrorHandler} from './internal/handlers/global-error.handler';
import {CartComponent} from './pages/cart/cart.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {OrderComponent} from './pages/order/order.component';
import {QrCodeComponent} from './pages/qr-code/qr-code.component';
import {ShareQrCodeComponent} from './pages/qr-code/share/share-qr-code.component';
import {StoreModule} from './pages/store/store.module';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    CartComponent,
    OrderComponent,
    QrCodeComponent,
    ShareQrCodeComponent,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    WebStorageModule,
    NoopAnimationsModule,
    NgbModule,
    NgxBootstrapIconsModule.pick(allIcons),
    NgxShimmerLoadingModule,
    NgxSpinnerModule,
    // ZXingScannerModule,
    QRCodeModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ComponentsModule,
    StoreModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt-BR'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}


