import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ask-to-confirm-modal',
  template: `
    <div class="modal-header">
      <h5>{{title}}</h5>
    </div>
    <div class="modal-body p-5 text-center">
      <h6>{{message}}</h6>
    </div>
    <div class="modal-footer">
      <div class="mr-2">
        <button class="btn btn-outline-danger"
                (click)="onClickCancelEvent($event)">
          {{cancelBtnMessage}}
        </button>
      </div>
      <div class="">
        <button class="btn btn-success"
                (click)="onClickConfirmEvent($event)">
          {{confirmBtnMessage}}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./ask-to-confirm-modal.component.css']
})
export class AskToConfirmModalComponent implements OnInit {
  @Input()
  title = 'Atenção';
  @Input()
  message = 'Confirma esta ação?';
  @Input()
  confirmBtnMessage = 'Sim';
  @Input()
  cancelBtnMessage = 'Não';
  @Output()
  emitDataConfirm = new EventEmitter<unknown>();
  @Output()
  emitDataCancel = new EventEmitter<unknown>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onClickConfirmEvent($event: MouseEvent): void {
    this.emitDataConfirm.next();
    this.activeModal.close();
  }

  onClickCancelEvent($event: MouseEvent): void {
    this.emitDataCancel.next();
    this.activeModal.close();
  }

}
