import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BottomNavbarComponent } from './bottom-navbar/bottom-navbar.component';
import { ButtonAddToCartComponent } from './button-add-to-cart/button-add-to-cart.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { StoreInfoComponent } from './store-info/store-info.component';
import { FbIconComponent } from './icons/fb-icon/fb-icon.component';
import { InstagramIconComponent } from './icons/instagram-icon/instagram-icon.component';
import { LoaderComponent } from './loader/loader.component';
import { AddRemoveConfirmButtonComponent } from './add-remove-confirm-button/add-remove-confirm-button.component';
import { TitleComponent } from './title/title.component';
import { AskToConfirmModalComponent } from './ask-to-confirm-modal/ask-to-confirm-modal.component';

@NgModule({
  declarations: [
    LoaderComponent,
    HeaderComponent,
    StoreInfoComponent,
    TitleComponent,
    FooterComponent,
    AddRemoveConfirmButtonComponent,
    BottomNavbarComponent,
    ButtonAddToCartComponent,
    AskToConfirmModalComponent,
    FbIconComponent,
    InstagramIconComponent,
  ],
  exports: [
    LoaderComponent,
    HeaderComponent,
    StoreInfoComponent,
    TitleComponent,
    FooterComponent,
    AddRemoveConfirmButtonComponent,
    BottomNavbarComponent,
    ButtonAddToCartComponent,
    AskToConfirmModalComponent,
    FbIconComponent,
    InstagramIconComponent,
  ],
  imports: [
    CommonModule,
    NgxSpinnerModule,
    NgxBootstrapIconsModule
    // ZXingScannerModule,
  ]
})
export class ComponentsModule {
}
