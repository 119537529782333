<div *ngIf="!currentStore?.isOpen; else bottomNavbar"></div>
<ng-template #bottomNavbar>
  <div class="bottom-navbar py-4">
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="display:block;">
      <div class="navbar-nav row">
        <div class="nav-item text-center col-4">
          <button  class="btn btn-link" (click)="onCartClick($event)">
            <i-bs [name]="'cart'" class="text-primary"></i-bs>
            <span *ngIf="cartCounterValue > 0"
                  class="badge badge-pill badge-success cart-counter">
              {{cartCounterValue}}
            </span>
          </button>
        </div>
        <div class="nav-item text-center col-4">
          <button  class="btn btn-link" (click)="onOrderClick($event)">
            <i-bs [name]="'list-nested'" class="text-primary"></i-bs>
          </button>
        </div>
        <div class="nav-item text-center col-4">
          <button  class="btn btn-link" (click)="onShareClick($event)">
            <i-bs [name]="'share'" class="text-primary"></i-bs>
          </button>
        </div>
      </div>
    </nav>
  </div>
</ng-template>
