import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-add-remove-confirm-button',
  templateUrl: './add-remove-confirm-button.component.html',
  styleUrls: ['./add-remove-confirm-button.component.css']
})
export class AddRemoveConfirmButtonComponent implements OnInit {

  @Input()
  counter = 0;

  @Output()
  removeClick = new EventEmitter<void>();

  @Output()
  addClick = new EventEmitter<void>();

  @Output()
  confirmClick = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClickRemoveEvent(_: Event): void {
    this.removeClick.emit();
  }

  onClickAddEvent(_: Event): void {
    this.addClick.emit();
  }

  onClickConfirmEvent(_: Event): void {
    this.confirmClick.emit();
  }
}
