import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../../../models/product';

@Component({
  selector: 'app-share-qr-code',
  template: `
    <div (click)="activeModal.dismiss('Cross click')">
      <div class="modal-header text-center">
        <div class="mt-2 m-auto">
          <h6 class="text-center w-100"> Compartilhe o QR Code de sua conta</h6>
          <qrcode [qrdata]="qrCodeValue" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <button type="button" class="btn-close btn bg-white close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./share-qr-code.component.css']
})
export class ShareQrCodeComponent implements OnInit {
  @Input()
  qrCodeValue: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }
}
