import {Product} from './product';

interface Order {
  storeId: string;
  orderIdExternal: string;
  orderItems: OrderItem[];
}

interface OrderItem {
  name: string;
  productId: string;
  productIdExternal: string;
  price: number;
  categoryId: string;
  quantity: number;
  observation: string;
  orderId: string;
  storeId: string;
  externalSync: boolean;
  id: string;
}

function mapToOrderItems(products: Product[]): OrderItem[] {
  const items = products.map(value => {
    const orderItem = {
      name: value.name,
      productId: value.id,
      productIdExternal: value.productIdExternal,
      price: value.price,
      categoryId: value.categoryId,
      quantity: value.qty,
      observation: value.observation?.toString()
    } as unknown as OrderItem;
    return orderItem;
  });
  return items;
}

export {
  Order,
  OrderItem,
  mapToOrderItems
};
