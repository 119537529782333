<app-loader *ngIf="isLoading; else loaded"></app-loader>
<ng-template #loaded>
  <div class="cart-holder">
    <div class="cart-body">
      <div class="row align-middle justify-content-between ml-0 mr-0">
        <div title="Voltar" class="icon-btn" (click)="onClickBack($event)">
          <i-bs name="arrow-left-circle" class="icon-back"></i-bs>
        </div>
        <div class="category-name-title">
          <h4 class="text-center m-lg-4">
            <span class="text-muted mr-1">Seu carrinho em</span>
            <p class="font-weight-bold">{{currentStore?.name}}</p>
          </h4>
        </div>
        <div title="Esvaziar" class="icon-btn" (click)="onClickClean($event)">
          <i-bs name="trash" class="icon-back"></i-bs>
        </div>
      </div>
      <div class="cart-list list-group" *ngIf="cart">
        <div class="list-group-item" *ngFor="let prod of cart?.cartProducts">
          <div class="row">
            <div class="mb-2 m-sm-auto m-md-auto m-lg-auto text-center text-sm-left flex-row col-xs-12 col-sm-4 col-md-6">
              <div>
                <h3>
                  {{prod.name}}
                </h3>
              </div>
              <div class="text-muted" *ngIf="prod.observation?.length > 0">
                Observações:
                <small>{{prod.observation}}</small>
              </div>
            </div>
            <div class="mb-2 m-sm-auto m-md-auto m-lg-auto text-center text-sm-right col-xs-12 col-sm-3 col-md-4">
              <h3>
                <span class="text-muted mr-3 font-weight-light"
                      style="font-size: 16px;">
                  {{prod.qty || 1}}x
                </span>
                {{prod.price | currency:'BRL':true}}
              </h3>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 mt-sm-2 mt-md-2">
              <button type="button" class="btn btn-outline-danger btn-block text-capitalize"
              (click)="onClickRemove(prod)">REMOVER</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-navbar list-group" *ngIf="cart">
      <div class="list-group-item">
        <div class="row">
          <div class="col-2">
            <h4 class="mt-2 mb-2 font-weight-bold text-left">SubTotal</h4>
          </div>
          <div class="col-10 text-right">
            <h4 class="mt-2 mb-2 font-weight-bold">{{getTotal() | currency:'BRL'}}</h4>
          </div>
        </div>
      </div>
      <div class="list-group-item">
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-success btn-lg btn-block text-capitalize" (click)="onClickSend($event)">Confirmar Pedido</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
