import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-button-add-to-cart',
  templateUrl: './button-add-to-cart.component.html',
  styleUrls: ['./button-add-to-cart.component.css']
})
export class ButtonAddToCartComponent implements OnInit {

  @Output()
  onClickAdd: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onClickEvent(e: Event): void {
    this.onClickAdd.emit();
  }

}
