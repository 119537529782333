import {Component, OnInit} from '@angular/core';
import {Category} from '../../../models/category';
import {GroupCategory} from '../../../models/groupCategory';
import {Store} from '../../../models/store';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-category-list',
  template: `
    <div>
      <div *ngIf="selectedGroupCategory != null && selectedCategory == null" class="row mb-4 align-middle">
        <div title="Voltar" class="col-1 back-btn" (click)="onClickBackToGroupCategories($event)">
          <i-bs name="arrow-left-circle" class="icon-back"></i-bs>
        </div>
        <div class="col-10 m-auto category-name-title">
          <h4 class="font-weight-bold text-center m-auto m-lg-4">
            {{selectedGroupCategory?.name}}
          </h4>
        </div>
      </div>
      <div *ngIf="!selectedCategory; else categorySelectedTemplate">
        <div *ngIf="isLoading; else categoriesLoadedTemplate">
          <app-category-shimmer></app-category-shimmer>
        </div>
        <ng-template #categoriesLoadedTemplate>
          <div class="row">
            <div *ngFor="let category of categories" class="category-holder col-md-3 col-sm-6 mb-4 ">
              <div *ngIf="!isCategoryType(category); else isCategory">
                <div class="category-content card card-body shadow-sm h-100 align-content-center text-center bg-light"
                     style="{{getCoverStyle(category['photoUrl'])}}"
                     (click)="onClick(category)">
                  <p class="category-name">
                    {{category['name']}}
                  </p>
                </div>
              </div>
              <ng-template #isCategory>
                <div class="category-content card card-body shadow-sm h-100 align-content-center text-center bg-light"
                     style="{{getCoverStyle(category['photoUrl'])}}"
                     (click)="onClick(category)">
                  <p class="category-name">
                    {{category['name']}}
                  </p>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-template #categorySelectedTemplate>
        <app-product-list [category]="selectedCategory"
                          [isOpen]="currentStore?.isOpen"
                          (clickBackEmitter)="onClickBackToCategories($event)">
        </app-product-list>
      </ng-template>
    </div>
  `,
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {
  isLoading = true;
  currentStore: Store;
  isSingleGroup = true;
  categories: [] = [];
  selectedGroupCategory?: GroupCategory = null;
  selectedCategory: Category = null;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.currentStore = this.dataService.store();
    this.initData();
  }

  initData(): void {
    this.selectedGroupCategory = null;
    this.dataService.getGroupCategories()
      .then(resp => {
        this.isLoading = false;
        this.isSingleGroup = resp.length === 1;
        if (this.isSingleGroup){
          this.categories = resp[0].categories as [];
          return;
        }
        this.categories = resp as [];
      });
  }

  onClick(category: object): void {
    if (!category.hasOwnProperty('categories')){
      this.selectedCategory = category as Category;
      return;
    }
    // @ts-ignore
    this.categories = category.categories as [];
    this.selectedGroupCategory = category as GroupCategory;

  }

  onClickBackToGroupCategories($event: any): void {
    this.initData();
  }

  onClickBackToCategories($event: any): void {
    this.selectedCategory = null;
  }

  getCoverStyle(photoUrl: string): string {
    if (!photoUrl) {
      console.log("não tem fotos")
      return '';
    }
    photoUrl = photoUrl.replace('/upload/', '/upload/c_scale,w_250,fl_lossy,f_auto/');

    // tslint:disable-next-line:max-line-length
    return `background-image: url('${photoUrl}'); background-size: cover; background-repeat: no-repeat; background-position: center center;`;
  }

  isCategoryType(category: object): boolean {
    return !category.hasOwnProperty('categories');
  }
}
