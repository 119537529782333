import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {isEmpty} from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {AccountOrders} from '../../models/accountOrder';
import {Store} from '../../models/store';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {
  storeAlias: string;
  currentStore: Store;
  accountOrders: AccountOrders;
  isLoading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private toastr: ToastrService,
              private location: Location) {
  }

  ngOnInit(): void {
    this.activatedRoute
      .params
      .subscribe(parameters => {
        const {storeAlias} = parameters;
        if (!storeAlias) {
          this.location.back();
          return;
        }
        // Valid route was found, proceed...
        this.storeAlias = storeAlias;
        this.getStore(storeAlias);
      });
  }

  async getStore(storeAlias): Promise<void> {
    const resp = await this.dataService.getOrder(storeAlias);
    if (!resp || !resp.order) {
      this.backOnError('Você não possui acesso aos pedidos, peça ajuda a um garçom.');
      return;
    }
    if (isEmpty(resp.order.orderItems)) {
      this.backOnError('Você ainda não possui pedidos na sua conta.');
      return;
    }
    this.currentStore = resp.store;
    this.accountOrders = resp.order;
    this.isLoading = false;
  }


  onClickBack(_: MouseEvent): void {
    this.location.back();
  }

  backOnError(errMsg: string): void {
    this.toastr.warning(errMsg);
    this.location.back();
  }
}
