import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../../../models/product';

@Component({
  selector: 'app-product-detail',
  template: `
    <div (click)="activeModal.dismiss('Cross click')">
      <div class="modal-header" style="background-image: url('{{product?.photoUrl}}'); background-size: cover; background-repeat: no-repeat; background-position: center center;">
        <button type="button" class="btn bg-white close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <h4 class="font-weight-bold p-name">{{product?.name}}</h4>
        <div class="mt-2 mb-4 text-muted">{{product?.description}}</div>
        <h5 class="font-weight-bold">{{ product?.price | currency:'BRL':true }}</h5>
      </div>
    </div>
  `,
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  @Input()
  product: Product = null;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }
}
