import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Category} from '../../../models/category';
import {Product} from '../../../models/product';
import {DataService} from '../../../services/data.service';
import {ProductAddToCartComponent} from '../product-add-to-cart/product-add-to-cart.component';
import {ProductDetailComponent} from '../product-detail/product-detail.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit {
  @Input()
  isOpen: boolean;
  @Input()
  category: Category;
  @Input()
  products: Product[] = [];
  @Output()
  clickBackEmitter: EventEmitter<unknown> = new EventEmitter<unknown>();
  isLoading = true;

  constructor(private dataService: DataService,
              private modalService: NgbModal) {
  }


  ngOnInit(): void {
    this.dataService
      .getProducts(this.category.id)
      .then((products: Product[]) => {
        this.products = products;
        this.isLoading = false;
      });
  }

  onClickBack($event: MouseEvent): void {
    this.clickBackEmitter.emit($event);
  }

  onProductClick(product: Product): void {
    const modalRef = this.modalService.open(ProductDetailComponent, {centered: true});
    modalRef.componentInstance.product = product;
  }

  getPhotoUrl(photoUrl: string): string {
    photoUrl = photoUrl.replace('/upload/', '/upload/c_scale,h_100,w_100,fl_lossy,f_auto/');
    return photoUrl;
  }

  onClickAddToCart(product: Product): void {
    const modalRef = this.modalService.open(ProductAddToCartComponent, {centered: true});
    product.qty = 1;
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.emitData.subscribe(($prod) => {
      this.addToCart($prod);
    });
  }

  addToCart(product: Product): void {
    if (!product.qty) {
      return;
    }
    this.dataService.addProductCountInCart(product);
  }
}
