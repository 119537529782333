<div class="container-fluid">
  <div class="row mt-3 mb-3">
    <div class="col-6 text-center">
      <label class="btn btn-lg btn-outline-primary" (click)="onClickRemoveEvent($event)">
        <i-bs name="dash" class="ic-plus"></i-bs>
      </label>
      <label>
        <div class="counter-text font-weight-bold">{{ counter }}</div>
      </label>
      <label class="btn btn-lg btn-outline-primary" (click)="onClickAddEvent($event)">
        <i-bs name="plus" class="ic-plus"></i-bs>
      </label>
    </div>
    <div class="col-6">
      <div class="add-cart-group-button">
        <label class="btn btn-primary btn-lg btn-block" (click)="onClickConfirmEvent($event)">
          <span class="text-capitalize mr-2">Adicionar</span>
        </label>
      </div>
    </div>
  </div>
</div>
