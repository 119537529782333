import {Component, Input, OnInit} from '@angular/core';

export const loadPlaceHolder = (e) => {
  e.src = 'https://img.icons8.com/dotty/344/not-applicable.png';
};

@Component({
  selector: 'app-header',
  template: `
    <nav class="navbar navbar-expand-lg navbar-light navbar-default justify-content-center"
         style="background-image: url('{{photoUrl}}'); background-size: cover; background-repeat: no-repeat; background-position: center center;">
      <div class="logo">
        <img src="{{logoUrl}}" class="img-fluid" alt="{{storeName}}"
             onerror="this.src = 'https://img.icons8.com/pastel-glyph/512/error.png';this.style.width = '100px';this.style.height = '100px'; this.title='Não foi possível carregar imagem.'">
      </div>
    </nav>
  `,
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input()
  photoUrl: string;
  @Input()
  logoUrl: string;
  @Input()
  storeName: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
