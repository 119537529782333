import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { ComponentsModule } from '../../components/components.module';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryShimmerComponent } from './category-list/shimmer/category-shimmer.component';
import { ProductAddToCartComponent } from './product-add-to-cart/product-add-to-cart.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductListComponent } from './product-list/product-list.component';
import { ProductShimmerComponent } from './product-list/shimmer/product-shimmer.component';

import { StoreRoutingModule } from './store-routing.module';
import { StoreComponent } from './store.component';

@NgModule({
  declarations: [
    StoreComponent,
    CategoryListComponent,
    CategoryShimmerComponent,
    ProductListComponent,
    ProductDetailComponent,
    ProductShimmerComponent,
    ProductAddToCartComponent
  ],
  imports: [
    CommonModule,
    StoreRoutingModule,
    ComponentsModule,
    NgxShimmerLoadingModule,
    NgxBootstrapIconsModule,
    FormsModule,
  ],
  exports: []
})
export class StoreModule {
}
