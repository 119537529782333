import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  private router: Router;

  constructor(injector: Injector, private zone: NgZone) {
    setTimeout(() => {
      this.router = injector.get(Router);
    });
  }

  handleError(error): void {
    console.log(error);
    this.zone.run(() => this.router.navigate(['/error']));
  }
}
