import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Cart} from '../../models/cart';
import {Store} from '../../models/store';
import {ShareQrCodeComponent} from '../../pages/qr-code/share/share-qr-code.component';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-bottom-navbar',
  templateUrl: './bottom-navbar.component.html',
  styleUrls: ['./bottom-navbar.component.css']
})
export class BottomNavbarComponent implements OnInit {
  storeAlias: string;
  cartCounterValue = 0;
  currentStore: Store;
  cart: Cart;
  isLoading = true;

  constructor(private dataService: DataService,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService,
              private modalService: NgbModal,
              private location: Location,
              private router: Router) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parameters => {
      const {storeAlias} = parameters;
      if (!storeAlias) {
        this.router.navigate(['not-found']);
        return;
      }
      // Valid route was found, proceed...
      this.storeAlias = storeAlias;
      this.getStore(storeAlias);
    });
  }

  private async getStore(storeAlias): Promise<void> {
    const resp = await this.dataService.getStore(storeAlias);
    if (!resp) {
      this.location.back();
      return;
    }
    this.currentStore = resp;
    this.getCart();
  }

  private getCart(): void {
    this.dataService.getCartObservable()
      .subscribe(value => {
        this.cart = value;
        this.cartCounterValue = this.cart?.cartProducts && this.cart?.cartProducts.length ?
          this.cart.cartProducts
            .map(cp => cp.qty)
            .reduce((previousValue, currentValue) => previousValue + currentValue)
          : 0;
        this.isLoading = false;
      });
  }

  async onOrderClick(_: MouseEvent): Promise<void> {
    if (!this.currentStore.isOpen) {
      this.toastr.warning('No momento não é possível visualizar sua conta.');
      return;
    }
    await this.router.navigate(['orders', this.storeAlias]);
  }

  onCartClick(_: MouseEvent): void {
    if (this.cartCounterValue === 0) {
      this.toastr.warning('Seu carrinho está vazio.');
      return;
    }
    this.router.navigate([`/cart/${this.storeAlias}`]);
  }

  onShareClick(_: MouseEvent): void {
    const orderIdExternal = this.dataService.orderIdExternal();
    if (!orderIdExternal) {
      this.toastr.warning('Você ainda não tem uma conta ativa, peça ajuda a um garçom.');
      return;
    }
    const modalRef = this.modalService.open(ShareQrCodeComponent, {centered: true, windowClass: 'fit'});
    modalRef.componentInstance.qrCodeValue = orderIdExternal;
  }
}
