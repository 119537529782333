import {v4 as uuidv4} from 'uuid';
import {Product} from './product';

class Cart {
  cartProducts: Product[] = [];
  total = 0;
  storeAlias: string;
  createdAt: Date = new Date();
}

function removeProduct(cart: Cart, product: Product): void {
  cart.cartProducts.forEach((item, index) => {
    if (item.cartId !== product.cartId) {
      return;
    }
    cart.cartProducts.splice(index, 1);
  });
  updateTotal(cart);
}

function addProduct(cart: Cart, product: Product): void {
  const clone = Object.assign({}, product) as Product;
  clone.cartId = uuidv4();
  cart.cartProducts.push(clone);
  updateTotal(cart);
}

function updateTotal(cart: Cart): void {
  cart.total = 0;
  cart.cartProducts.forEach(value => cart.total += value.price);
}

export {
  removeProduct,
  addProduct,
  updateTotal,
  Cart
};
