import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-category-shimmer',
  template: `
    <div class="row">
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
      <div class="col-md-3 col-sm-6 mb-4 ">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'140px'"></ngx-shimmer-loading>
      </div>
    </div>
  `,
})
export class CategoryShimmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
