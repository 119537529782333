import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './pages/cart/cart.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { OrderComponent } from './pages/order/order.component';
import { QrCodeComponent } from './pages/qr-code/qr-code.component';
import { StoreComponent } from './pages/store/store.component';
import { BioComponent } from './pages/bio/bio.component';

const routes: Routes = [
  {
    path: 'menu/:storeAlias',
    component: StoreComponent,
    loadChildren: () => import('./pages/store/store.module').then(m => m.StoreModule),
    pathMatch: 'full'
  },
  {
    path: 'bio/:storeAlias',
    component: BioComponent,
    loadChildren: () => import('./pages/bio/bio.module').then(m => m.BioModule),
    pathMatch: 'full'
  },
  { path: 'cart/:storeAlias', component: CartComponent, pathMatch: 'full' },
  { path: 'orders/:storeAlias', component: OrderComponent, pathMatch: 'full' },
  { path: 'qr-code/:storeAlias', component: QrCodeComponent, pathMatch: 'full' },
  { path: 'not-found', component: NotFoundComponent, pathMatch: 'full' },
  { path: '', redirectTo: '/not-found', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
 })
export class AppRoutingModule {
}
