import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-loader',
  template: `
    <ngx-spinner bdColor="rgba(13,21,90,1.0)" size="medium" color="#fff" type="square-loader" [fullScreen]="true">
      <div class="font-weight-bold text-white message" [innerHTML]="message"></div>
    </ngx-spinner>
  `,
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() message = 'Carregando Netmenu...';

  constructor(private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.spinner.show();
  }

  hide(): void {
    this.spinner.hide();
  }

}
