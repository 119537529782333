import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Product} from '../../../models/product';

@Component({
  selector: 'app-product-add-to-cart',
  template: `
    <div class="modal-header" style="background-image: url('{{product?.photoUrl}}'); background-size: cover; background-repeat: no-repeat; background-position: center center;">
      <button type="button" class="btn bg-white close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <h4 class="font-weight-bold p-name">{{product?.name}}</h4>
      <div class="mt-2 mb-4 text-muted">{{product?.description}}</div>
      <h5 class="font-weight-bold">{{ product?.price | currency:'BRL':true }}</h5>
      <div class="product-observation">
        <div class="mt-2 mb-1">
          <span class="small text-muted">Algum comentário?</span>
        </div>
        <div class="w-100">
          <textarea rows="4"
                    maxlength="255"
                    class="w-100 bg-light border-light product-observation"
                    [(ngModel)]="productObservation">
          </textarea>
        </div>
      </div>
      <div class="product-counter">
        <app-add-remove-confirm-button
          (removeClick)="onClickRemoveEvent()"
          (addClick)="onClickAddEvent()"
          (confirmClick)="onClickConfirmEvent()"
          [counter]="product.qty">
        </app-add-remove-confirm-button>
      </div>
    </div>
  `,
  styleUrls: ['./product-add-to-cart.component.css']
})
export class ProductAddToCartComponent implements OnInit {
  productObservation: string;
  @Input()
  product: Product;
  @Output() emitData = new EventEmitter<Product>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  onClickRemoveEvent(): void {
    if (this.product.qty > 1) {
      this.product.qty--;
    }
  }

  onClickAddEvent(): void {
    this.product.qty++;
  }

  onClickConfirmEvent(): void {
    if (this.productObservation) {
      this.product.observation = this.productObservation;
    }
    this.emitData.next(this.product);
    this.activeModal.close();
  }
}
