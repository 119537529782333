<app-loader *ngIf="isLoading; else loaded"></app-loader>
<ng-template #loaded>
  <div class="cart-holder" *ngIf="currentStore">
    <div class="cart-body">
      <div class="row align-middle justify-content-between ml-0 mr-0">
        <div title="Voltar" class="icon-btn" (click)="onClickBack($event)">
          <i-bs name="arrow-left-circle" class="icon-back"></i-bs>
        </div>
        <div class="category-name-title">
          <h4 class="text-center m-lg-4">
            <span class="text-muted mr-1">Seus Pedidos em</span>
            <p class="font-weight-bold">{{currentStore.name}}</p>
          </h4>
        </div>
        <div title="Esvaziar" class="invisible icon-btn">
          <i-bs name="trash" class="icon-back"></i-bs>
        </div>
      </div>
      <div class="cart-list list-group" *ngIf="accountOrders">
        <div class="list-group-item" *ngFor="let prod of accountOrders.orderItems">
          <div class="row">
            <div class="col-sm-12 col-md-8">
              <h5 class="mt-1 mb-1 text-left font-weight-light">
                  {{prod.name}}
              </h5>
            </div>
            <div class="col-6 col-sm-6 text-sm-left col-md-2 text-md-left mt-auto mb-auto">
              <span *ngIf="prod.externalSync" class="f12 font-weight-light badge badge-success">Confirmado</span>
              <span *ngIf="!prod.externalSync" class="f12 font-weight-light badge badge-warning">Não confirmado</span>
            </div>
            <div class="col-6 col-sm-6 text-sm-right col-md-2 text-md-right">
              <h4 class="mt-1 mb-1 text-right">
                <span class="text-muted mr-3 font-weight-light" style="font-size: 16px;">{{prod.quantity}}x</span>{{prod.price | currency:'BRL':true}}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart-navbar list-group" *ngIf="accountOrders">
      <div class="list-group-item">
        <div class="row">
          <div class="col-6">
            <h5 class="mt-2 mb-2 text-left">Subtotal</h5>
          </div>
          <div class="col-6 text-right">
            <h5 class="mt-2 mb-2">{{accountOrders.subTotal | currency:'BRL'}}</h5>
          </div>
        </div>
        <div class="row" *ngIf="accountOrders.serviceCharge">
          <div class="col-6  mt-auto mb-auto">
            <h5 class="mt-2 mb-2 text-left">Taxa de serviço</h5>
          </div>
          <div class="col-6 text-right mt-auto mb-auto">
            <h5 class="mt-2 mb-2">{{accountOrders.serviceCharge }}%</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h4 class="mt-2 mb-2 font-weight-bold text-left">Total</h4>
          </div>
          <div class="col-6 text-right  mt-auto mb-auto">
            <h4 class="mt-2 mb-2 font-weight-bold">{{accountOrders.total | currency:'BRL'}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
