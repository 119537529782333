import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {isEmpty} from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {AskToConfirmModalComponent} from '../../components/ask-to-confirm-modal/ask-to-confirm-modal.component';
import {Cart} from '../../models/cart';
import {Product} from '../../models/product';
import {Store} from '../../models/store';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {

  storeAlias: string;
  currentStore: Store;
  cart: Cart;
  isLoading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private dataService: DataService,
              private location: Location,
              private toastr: ToastrService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(parameters => {
      const {storeAlias} = parameters;
      if (!storeAlias) {
        this.location.back();
        return;
      }
      // Valid route was found, proceed...
      this.storeAlias = storeAlias;
      this.getStore(storeAlias);
    });
  }

  private async getStore(storeAlias: string): Promise<void> {
    const resp = await this.dataService.getStore(storeAlias);
    if (!resp) {
      this.router.navigate(['not-found']);
      return;
    }
    this.currentStore = resp;
    this.getCart(storeAlias);
  }

  private getCart(storeAlias: string): void {
    this.dataService.getCartObservable().subscribe(value => {
      if (isEmpty(value.cartProducts)) {
        this.router.navigate([`menu/${storeAlias}`]);
        return;
      }
      this.cart = value;
      this.isLoading = false;
    });
  }

  onClickBack(_: MouseEvent): void {
    this.location.back();
  }

  getTotal(): number {
    const cartProdPrices = this.cart.cartProducts.map(prod => prod.price * (prod.qty || 1));
    if (cartProdPrices.length > 0) {
      return cartProdPrices.reduce((previousValue, currentValue) => previousValue + currentValue);
    }
    return 0;
  }

  onClickClean(_: MouseEvent): void {
    const modalRef = this.modalService.open(AskToConfirmModalComponent, {centered: true});
    modalRef.componentInstance.title = 'Atenção';
    modalRef.componentInstance.message = 'Deseja remover todos os produtos do pedido?';
    modalRef.componentInstance.confirmBtnMessage = 'Sim';
    modalRef.componentInstance.cancelBtnMessage = 'Não';
    modalRef.componentInstance.emitDataConfirm.subscribe(() => {
      this.cleanCartConfirm();
    });
  }

  onClickSend(_: MouseEvent): void {
    if (this.dataService.orderIdExternal()) {
      this.isLoading = true;
      this.dataService.sendOrder()
        .then(resp => {
          if (resp) {
            this.cleanCartConfirm();
            return;
          }
          this.isLoading = false;
          this.toastr.error('Não foi possível enviar seu pedido, tente novamente ou peça ajuda a um garçom.');
          return;
        })
        .catch(reason => {
          this.isLoading = false;
          this.toastr.error('Não foi possível enviar seu pedido, tente novamente ou peça ajuda a um garçom.');
          return;
        });
      return;
    }
    this.router.navigate([`qr-code/${this.storeAlias}`]);
  }

  private cleanCartConfirm(): void {
    this.dataService.cleanCart();
    this.location.back();
  }

  onClickRemove(prod: Product): void {
    this.dataService.removeProductInCart(prod);
  }
}
