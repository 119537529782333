import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Store} from '../../models/store';
import {DataService} from '../../services/data.service';

@Component({
  selector: 'app-footer',
  template: `
    <footer [class.with-cart]="currentStore?.isOpen">
      <div class="brand container-fluid align-content-center footer-top">
        <p class="text-center powered">Powered by:</p>
        <a href="https://www.mdc.com.br/?utm_source=netmenu-{{currentStore.alias}}&utm_medium=store-footer&utm_campaign=netmenu" target="_blank">
          <img src="../../../assets/images/mdc-logo.png">
        </a>
        <p class="copyright text-center m-0">© {{currentYear}} MDC Software <span class="text-danger">❤</span>️</p>
        <p class="text-monospace small text-center">[{{appVersion}}]</p>
      </div>
    </footer>
  `,
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  appVersion = environment.appVersion;
  currentYear = new Date().getFullYear();
  currentStore: Store;

  constructor(private dataService: DataService) {
  }

  ngOnInit(): void {
    this.currentStore = this.dataService.store();
  }

}
