import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DataService} from '../../services/data.service';


@Component({
  selector: 'app-qr-code',
  template: `
    <app-loader [message]="loaderMessage" *ngIf="isLoading; else loaded"></app-loader>
    <ng-template #loaded>
      <div class="qr-scan-area">
        <div class="qr-area-top">
          <button style="z-index: 10" type="button" class="btn btn-outline-warning close ml-4 mt-4 position-absolute"
                  aria-label="Close"
                  (click)="onCLickBack($event)">
            <i-bs name="arrow-left-circle" class="text-warning icon-back"></i-bs>
          </button>
          <h4 style="left: 1%; right: 1%;" class="mt-4 ml-5 mr-5 position-absolute text-center text-warning">
            Chame o garçom para autorizar seu primeiro pedido.
          </h4>
        </div>
        <zxing-scanner
          #scanner
          [(device)]="currentDevice"
          (scanSuccess)="onQRCodeValue($event)"
          (permissionResponse)="onHasPermission($event)">
        </zxing-scanner>
        <div class="qr-area d-flex">
          <div class="area"></div>
          <h6 class="position-absolute text-warning">
            Mire no QR Code
          </h6>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {

  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;
  result: string;
  isLoading = true;
  storeAlias: string;
  loaderMessage = 'Agora é só chamar o garçom para autorizar seu pedido';

  constructor(private dataService: DataService,
              private router: Router,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.activatedRoute.params.subscribe(parameters => {
      const {storeAlias} = parameters;
      if (!storeAlias) {
        this.location.back();
        return;
      }
      // Valid route was found, proceed...
      this.storeAlias = storeAlias;
    });
  }

  public onQRCodeValue(qrCodeValue: string): void {
    this.loaderMessage = 'Pronto! Enviando seu pedido...';
    this.isLoading = true;
    this.dataService.validateAndPostOrder(qrCodeValue)
      .then((resp: boolean) => {
        if (resp) {
          this.cleanCartConfirm();
          return;
        }
        this.onErroGoBack();
        return;
      }, (error: any) => {
        console.error('error', error);
        this.onErroGoBack();
      });
  }

  private cleanCartConfirm(): void {
    this.dataService.cleanCart();
    this.location.back();
  }

  private onErroGoBack(): void {
    this.toastr.error('Não foi possível enviar seu pedido, tente novamente ou peça ajuda a um garçom.');
    this.location.back();
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
    if (!this.hasPermission) {
      this.toastr.error('Para efetuar o pedido é preciso autorizar o uso da câmera.');
      return;
    }
    this.isLoading = !has;
  }

  onCLickBack($event: MouseEvent): void {
    $event.preventDefault();
    this.location.back();
  }
}
