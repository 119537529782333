class AppInfo {
  version: string;
  storeAlias: string;
  createdAt: Date;
}


export {
  AppInfo
};
