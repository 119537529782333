import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-shimmer',
  template: `
    <div class="row mt-5">
      <div class="col-1 mb-5">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'50px'"></ngx-shimmer-loading>
      </div>
      <div class="col-11 mb-5 text-center">
        <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'50px'"></ngx-shimmer-loading>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
      <div class="col-12 mb-1">
        <div class="row">
          <div class="col-2">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'100px'"></ngx-shimmer-loading>
          </div>
          <div class="col-10">
            <ngx-shimmer-loading [shape]="'rect'" [width]="'40%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'100%'" [height]="'20px'"></ngx-shimmer-loading>
            <ngx-shimmer-loading [shape]="'rect'" [width]="'30%'" [height]="'20px'"></ngx-shimmer-loading>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class ProductShimmerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
