import { Component, Input, OnInit } from '@angular/core';

import { BoxArrowInUpRight } from 'ngx-bootstrap-icons';

import { Store } from '../../models/store';

export const loadPlaceHolder = (e) => {
  e.src = 'https://img.icons8.com/dotty/344/not-applicable.png';
};

@Component({
  selector: 'app-store-info',
  template: `
    <div class="row store-info-header">
        <div class="container wrapper">
          <div class="row holder align-items-center">
            <div class="col d-flex">
              <div class="ic-holder">
                <i-bs name="geo-alt" class="ic-location pr-2"></i-bs>
                <i>📞</i>
              </div>
              <div class="address-holder pt-1">
                <p class="block-with-text">
                  {{store.address}}
                </p>
                <p class="mt-2 block-with-text">
                  <a href="tel:{{store.phoneNumber}}">{{store.phoneNumber}}</a>
                </p>
              </div>
            </div>
            <div class="col d-flex flex-row justify-content-center">
              <a class="ic-social" (click)="goToFb(this.store);">
                <app-fb-icon [class.disabled]="!store.facebook"></app-fb-icon>
              </a>
              <a class="ic-social" (click)="goToInstagram(this.store);">
                <app-instagram-icon [class.disabled]="!store.instagram"></app-instagram-icon>
              </a>
            </div>
            <div class="col d-flex text-truncate justify-content-end">
              <div class="ic-holder">
                <i-bs name="alarm" class="ic-alarm pr-2">
                </i-bs>
              </div>
              <div class="address-holder pt-1">
                <p>Funcionamento:</p>
                <p
                  [class.text-success]="!store?.isOpen"
                  [class.text-success]="!store?.isOpen"
                  class="font-weight-bold">{{store?.isOpen ? 'ABERTO' : 'ABERTO'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['./store-info.component.css']
})
export class StoreInfoComponent implements OnInit {
  @Input() store: Store;

  constructor() { }

  ngOnInit(): void { }

  goToFb(store: Store): void {
    if (store.facebook) {
      let cleanUrl = this.store.facebook.indexOf('//') > 0 ? store.facebook.split('//', 2)[1] : store.facebook;
      let url = 'https://' + cleanUrl.split('/', 2)[0] + '/' + cleanUrl.split('/', 2)[1];
      window.open(url, "_blank");
    }
  }

  goToInstagram(store: Store): void {
    if (store.instagram) {
      let cleanUrl = this.store.instagram.indexOf('//') > 0 ? store.instagram.split('//', 2)[1] : store.instagram;
      let url = 'https://' + cleanUrl.split('/', 2)[0] + '/' + cleanUrl.split('/', 2)[1];
      window.open(url, "_blank");
    }
  }
}
